<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Recomendaciones SST"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'RECOMENDACIONES_SST_' + this.items.NtpFullName + '_' + new Date().toLocaleDateString() + '.pdf'"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                :htmlToPdfOptions = "htmlToPdfOptions"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="font-family: 'Times New Roman', Times, serif; margin: 0; padding: 20px;">
                        <div style="width: 730px; margin: 50px auto; padding: 0px;">
                            <div>
                                <!-- Encabezado con 3 secciones -->
                                <div style="display: flex; width: 100%; font-family: Arial, sans-serif; border-bottom: 1px solid #000">
                                    <!-- Sección Izquierda - Logo -->
                                    <div style="flex: 1; display: flex; justify-content: center; align-items: center;  padding: 10px;">
                                        <img src="/static/logo_sp.png" alt="Logo" style="width: 140px; height: auto;">
                                    </div>

                                    <!-- Sección Central - Título -->
                                    <div style="flex: 2; display: flex; justify-content: center; align-items: center;  padding: 10px; text-align: center;">
                                        <h2 style="margin: 0; font-size: 14px; font-weight: bold;">RECOMENDACIONES DE SEGURIDAD Y SALUD EN EL TRABAJO</h2>
                                    </div>

                                    <!-- Sección Derecha - Información -->
                                    <div style="flex: 1; font-size: 9px; padding: 10px; text-align: center;">
                                        <div style="margin-bottom: 2px;"><strong>CÓDIGO: ST-FR-062</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>VERSIÓN: 02</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Fecha de Creación: 12/06/2019</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Última Revisión: 22/12/2022</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Páginas: 1 de 2</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Anexo: 00</strong></div>
                                    </div>
                                </div>

                                <!-- Puesto de Trabajo y Área -->
                                <div style="display: flex; width: 100%; margin-top: 10px; font-size: 12px;">
                                    <div style="flex: 1; border: 1px solid #000; padding: 5px;">
                                        PUESTO DE TRABAJO: <strong>{{ this.items.PstName }}</strong>
                                    </div>
                                    <div style="flex: 1; border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; padding: 5px;">
                                        ÁREA: <strong>{{ this.items.AreName }}</strong>
                                    </div>
                                </div>

                                <div style="display: grid; grid-template-columns: 4% 10% 19% 16% 51%; width: 100%; margin-top: 10px;">
                                    <!-- Cabecera -->
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">N°</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Peligros</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Descripción del Riesgo</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Lesiones o Daños</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; border-right: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Recomendaciones</div>

                                    <!-- Filas de detalles -->
                                    <template v-for="(detalle, index) in DangerSSTDetail">
                                        <!-- Si es el primer elemento del grupo, mostrar la numeración y el peligro -->
                                        <div 
                                            v-if="index === 0 || detalle.DsdDanger !== DangerSSTDetail[index - 1].DsdDanger" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'center', 
                                                padding: '5px', 
                                                borderTop: '1px solid #000', 
                                                borderLeft: '1px solid #000', 
                                                textAlign: 'center', 
                                                borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none',
                                                gridRow: 'span ' + contarFilas(detalle.DsdDanger) 
                                            }"
                                        >
                                            {{ obtenerNumero(detalle.DsdDanger) }}
                                        </div>


                                        
                                        <div v-if="index === 0 || detalle.DsdDanger !== DangerSSTDetail[index - 1].DsdDanger" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                padding: '5px', 
                                                borderTop: '1px solid #000', 
                                                borderLeft: '1px solid #000', 
                                                fontWeight: 'bold', 
                                                borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none',
                                                gridRow: 'span ' + contarFilas(detalle.DsdDanger) 
                                            }">
                                            {{ detalle.DsdDangerName }}
                                        </div>

                                        <!-- Campos que siempre se repiten en cada fila -->
                                        
                                        <div 
                                        :style="{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            padding: '5px', 
                                            borderTop: '1px solid #000', 
                                            borderLeft: '1px solid #000', 
                                            borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none'
                                        }">
                                            {{ detalle.DsdDescription }}
                                        </div>
                                        <div :style="{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            padding: '5px', 
                                            borderTop: '1px solid #000', 
                                            borderLeft: '1px solid #000', 
                                            borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none'
                                        }">
                                            {{ detalle.DsdDamage }}
                                        </div>

                                        <!-- Primera fila: Celda de recomendaciones con rowspan dinámico -->
                                        <div v-if="index === 0" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'justify',
                                                padding: '5px', 
                                                border: '1px solid #000', 
                                                textAlign: 'justify', 
                                                gridRow: 'span ' + DangerSSTDetail.length 
                                            }">
                                            <v-col style="margin-left: 15px;">
                                                <ol>
                                                    <li v-for="recomendacion in DangerSSTRecommendation" :key="recomendacion.DsrID">
                                                        {{ recomendacion.DsrDescription }}
                                                    </li>
                                                </ol>
                                            </v-col>
                                        </div>
                                    </template>

                                </div>

                                <div style="display: flex; width: 100%; margin-top: 10px; font-size: 12px; text-align: justify;">
                                    <div style="flex: 1; border: 1px solid #000; padding: 5px;">
                                        Mediante el presente documento se comprometen a cumplir con la ley 29783 y su reglamento D.S. N° 005-2012- TR, modificatorias y normas de seguridad vigentes, así como acatar lo establecido en el Reglamento Interno de Trabajo y de Seguridad y Salud de la empresa SUNSHINE EXPORT SAC., caso contrario serán sujetos a las sanciones pertinentes.
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; width: 100%; margin-top: 10px; font-size: 12px; text-align: justify; font-family: Arial, Helvetica, sans-serif;">
                                <div style="padding: 10px 20px 10px 80px;">
                                    <div style="margin-bottom: 10px;">
                                        <strong>Nombres:</strong> 
                                        <span style="display: inline-block; width: 200px; border-bottom: 1px solid #000; text-align: center;">{{ this.items.NtpName }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px;">
                                        <strong>Apellidos:</strong> 
                                        <span style="display: inline-block; width: 200px; border-bottom: 1px solid #000; text-align: center">{{ this.items.NtpSurnames }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px;">
                                        <strong>N° DNI:</strong> 
                                        <span style="display: inline-block; width: 215px; border-bottom: 1px solid #000; text-align: center">{{ this.items.PrsDocumentNumber }}</span>
                                    </div>
                                    <div style="margin-bottom: 10px;">
                                        <strong>Fecha de entrega:</strong> 
                                        <span style="display: inline-block; width: 153px; border-bottom: 1px solid #000; text-align: center">{{ new Date().toLocaleString('es-PE', { year: 'numeric', month: '2-digit', day: '2-digit' }).split(' ')[0] }}</span>
                                    </div>
                                    <div>
                                        <strong>Firma:</strong> 
                                        <span style="display: inline-block; width: 220px; border-bottom: 1px solid #000; text-align: center"></span>
                                    </div>
                                </div>
                                <div style="flex: 1; padding: 10px; display: flex; align-items: end;">
                                    <div style="width: 70px; height: 90px; border: 1px solid #000; text-align: center; display: flex; justify-content: center; align-items: center;">
                                        <img :src="fingerprintImage" v-if="fingerprintImage" 
                                                alt="Huella Dactilar" 
                                                style="max-width: 100%; max-height: 100%; object-fit: contain;" />
                                    </div>
                                </div>
                            </div>
                            <div class="html2pdf__page-break"/>
                            <div style="margin-top: 60px;">
                                <!-- Encabezado con 3 secciones -->
                                <div style="display: flex; width: 100%; font-family: Arial, sans-serif; border-bottom: 1px solid #000">
                                    <!-- Sección Izquierda - Logo -->
                                    <div style="flex: 1; display: flex; justify-content: center; align-items: center;  padding: 10px;">
                                        <img src="/static/logo_sp.png" alt="Logo" style="width: 140px; height: auto;">
                                    </div>

                                    <!-- Sección Central - Título -->
                                    <div style="flex: 2; display: flex; justify-content: center; align-items: center;  padding: 10px; text-align: center;">
                                        <h2 style="margin: 0; font-size: 14px; font-weight: bold;">RECOMENDACIONES DE SEGURIDAD Y SALUD EN EL TRABAJO</h2>
                                    </div>

                                    <!-- Sección Derecha - Información -->
                                    <div style="flex: 1; font-size: 9px; padding: 10px; text-align: center;">
                                        <div style="margin-bottom: 2px;"><strong>CÓDIGO: ST-FR-062</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>VERSIÓN: 02</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Fecha de Creación: 12/06/2019</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Última Revisión: 22/12/2022</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Páginas: 2 de 2</strong></div>
                                        <div style="margin-bottom: 2px;"><strong>Anexo: 00</strong></div>
                                    </div>
                                </div>

                                <!-- Puesto de Trabajo y Área -->
                                <div style="display: flex; width: 100%; margin-top: 10px; font-size: 12px;">
                                    <div style="flex: 1; border: 1px solid #000; padding: 5px;">
                                        PUESTO DE TRABAJO: <strong>{{ this.items.PstName }}</strong>
                                    </div>
                                    <div style="flex: 1; border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; padding: 5px;">
                                        ÁREA: <strong>{{ this.items.AreName }}</strong>
                                    </div>
                                </div>
                                
                                <div style="display: grid; grid-template-columns: 4% 10% 19% 16% 51%; width: 100%; margin-top: 10px;">
                                    <!-- Cabecera -->
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">N°</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Peligros</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Descripción del Riesgo</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Lesiones o Daños</div>
                                    <div style="background: #d0cece; padding: 5px; border-top: 1px solid #000; border-left: 1px solid #000; border-right: 1px solid #000; font-weight: bold; display: flex; align-items: center; justify-content: center;">Recomendaciones</div>

                                    <!-- Filas de detalles -->
                                    <template v-for="(detalle, index) in DangerSSTDetail">
                                        <!-- Si es el primer elemento del grupo, mostrar la numeración y el peligro -->
                                        <div 
                                            v-if="index === 0 || detalle.DsdDanger !== DangerSSTDetail[index - 1].DsdDanger" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'center', 
                                                padding: '5px', 
                                                borderTop: '1px solid #000', 
                                                borderLeft: '1px solid #000', 
                                                textAlign: 'center', 
                                                borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none',
                                                gridRow: 'span ' + contarFilas(detalle.DsdDanger) 
                                            }"
                                        >
                                            {{ obtenerNumero(detalle.DsdDanger) }}
                                        </div>


                                        
                                        <div v-if="index === 0 || detalle.DsdDanger !== DangerSSTDetail[index - 1].DsdDanger" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                padding: '5px', 
                                                borderTop: '1px solid #000', 
                                                borderLeft: '1px solid #000', 
                                                fontWeight: 'bold', 
                                                borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none',
                                                gridRow: 'span ' + contarFilas(detalle.DsdDanger) 
                                            }">
                                            {{ detalle.DsdDangerName }}
                                        </div>

                                        <!-- Campos que siempre se repiten en cada fila -->
                                        
                                        <div 
                                        :style="{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            padding: '5px', 
                                            borderTop: '1px solid #000', 
                                            borderLeft: '1px solid #000', 
                                            borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none'
                                        }">
                                            {{ detalle.DsdDescription }}
                                        </div>
                                        <div :style="{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            padding: '5px', 
                                            borderTop: '1px solid #000', 
                                            borderLeft: '1px solid #000', 
                                            borderBottom: index === DangerSSTDetail.length - 1 ? '1px solid #000' : 'none'
                                        }">
                                            {{ detalle.DsdDamage }}
                                        </div>

                                        <!-- Primera fila: Celda de recomendaciones con rowspan dinámico -->
                                        <div v-if="index === 0" 
                                            :style="{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'justify',
                                                padding: '5px', 
                                                border: '1px solid #000', 
                                                textAlign: 'justify', 
                                                gridRow: 'span ' + DangerSSTDetail.length 
                                            }">
                                            <v-col style="margin-left: 15px;">
                                                <ol>
                                                    <li v-for="recomendacion in DangerSSTRecommendation" :key="recomendacion.DsrID">
                                                        {{ recomendacion.DsrDescription }}
                                                    </li>
                                                </ol>
                                            </v-col>
                                        </div>
                                    </template>

                                </div>

                                <div style="display: flex; width: 100%; margin-top: 10px; font-size: 12px; text-align: justify;">
                                    <div style="flex: 1; border: 1px solid #000; padding: 5px;">
                                        Mediante el presente documento se comprometen a cumplir con la ley 29783 y su reglamento D.S. N° 005-2012- TR, modificatorias y normas de seguridad vigentes, así como acatar lo establecido en el Reglamento Interno de Trabajo y de Seguridad y Salud de la empresa SUNSHINE EXPORT SAC., caso contrario serán sujetos a las sanciones pertinentes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </vue-html2pdf>
                <!-- <v-card-actions>
                    <s-finger-print @fingerprintSaved="handleFingerprint"></s-finger-print>
                </v-card-actions> -->
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sHelper from "@/services/HelperService";
    import _sGenParam from "../../../services/General/ParameterService";
    //import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";

    export default{ 
       
        props:{
            items: {
				type: Object,
				default: null,
			},
            itemsst: {
                type: Object,
                default: null,
            },
            fingerprintImage: {
                type: String,
                default: null
            },
            preventIndividualDownload: {
                type: Boolean,
                default: false
            }
        },
        components:{
            VueHtml2pdf
        },
        data(){
            return{
                urlData: "",
                image: null,
                definitiveReview: null,
                DangerSSTDetail: [],
                DangerSSTRecommendation: [],
                htmlToPdfOptions: {
                    filename: `Recomendaciones_SST.pdf`,
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait'
                    },
                    html2canvas: {
                    useCORS: true,
                    scale: 7,
                    },
                },
            }
        },
        methods:{
            getPDFBlob() {
                return new Promise((resolve, reject) => {
                    try {
                    // Guardar una referencia al componente html2pdf
                    const html2PdfComponent = this.$refs.html2Pdf;
                    
                    // Guardar los handlers originales
                    const originalBeforeDownload = this.beforeDownload;
                    const originalHasDownloaded = this.hasDownloaded;
                    
                    // Variable para controlar si la promesa ya se resolvió
                    let isResolved = false;
                    
                    // Crear un nuevo handler para beforeDownload
                    this.beforeDownload = ({ html2pdf, options, pdfContent }) => {
                        // Capturar el PDF directamente desde la instancia html2pdf
                        html2pdf().set(options).from(pdfContent).outputPdf('blob').then(blob => {
                        if (!isResolved) {
                            isResolved = true;
                            resolve(blob);
                        }
                        
                        // Restaurar handlers originales
                        this.beforeDownload = originalBeforeDownload;
                        this.hasDownloaded = originalHasDownloaded;
                        
                        // Llamar al handler original si existe
                        if (typeof originalBeforeDownload === 'function') {
                            return originalBeforeDownload({ html2pdf, options, pdfContent });
                        }
                        return true; // Continuar con el proceso normal
                        }).catch(error => {
                        if (!isResolved) {
                            isResolved = true;
                            reject(error);
                        }
                        
                        // Restaurar handlers originales
                        this.beforeDownload = originalBeforeDownload;
                        this.hasDownloaded = originalHasDownloaded;
                        });
                        
                        // Prevenir la descarga automática retornando false
                        return false;
                    };
                    
                    // Iniciar la generación del PDF
                    html2PdfComponent.generatePdf();
                    
                    // Establecer un timeout más largo
                    setTimeout(() => {
                        if (!isResolved) {
                        isResolved = true;
                        
                        // Restaurar handlers originales
                        this.beforeDownload = originalBeforeDownload;
                        this.hasDownloaded = originalHasDownloaded;
                        
                        reject(new Error('No se pudo obtener el blob del PDF: timeout después de 10 segundos'));
                        }
                    }, 10000); // 10 segundos
                    
                    } catch (error) {
                    console.error('Error en getPDFBlob:', error);
                    reject(error);
                    }
                });
            },
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				//console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				//console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				//console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                //console.log(`On Before PDF Generation`)
                if(!this.preventIndividualDownload) {
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
                }
            },
            getImagePerson(document) {
                _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
                if (rsp.status === 200) {
                    this.image = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente
                    return this.image;
                } else {
                    console.error("No se pudo cargar la imagen");
                }
                }).catch((error) => {
                console.error("Error al obtener la imagen:", error);
                });
            },
            loadParameters() {
                _sGenParam
                    .search({ PrmName: "RH-FR-039" }, this.$fun.getUserID())
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.definitiveReview = resp.data.PrmValue;
                        }
                    });
            },
            // handleFingerprint(image) {
            //     this.fingerprintImage = image; // Guarda la huella en una variable
            // },
            contarFilas(dangerId) {
                return this.DangerSSTDetail.filter(detalle => detalle.DsdDanger === dangerId).length;
            },
            obtenerNumero(dangerId) {
                const idsUnicos = [...new Set(this.DangerSSTDetail.map(detalle => detalle.DsdDanger))];
                return idsUnicos.indexOf(dangerId) + 1;
            }
        },
        mounted(){
            if (this.itemsst) {
                this.DangerSSTDetail = this.itemsst.DangerSSTDetail || [];
                this.DangerSSTRecommendation = this.itemsst.DangerSSTRecommendation || [];
            }
        }
    }
</script>