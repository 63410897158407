<template>
  <div class="d-flex align-center flex-column ">
      <v-card>
        
          <s-toolbar label="Contrato por Incremento de Actividades" dark color="#8e8f91" close @close="$emit('close')"></s-toolbar>
          <v-card-title style="font-size: small;">
              <v-row>
                  <v-col align="right">
                      <v-tooltip bottom="">
                          <template v-slot:activator="{ on }">
                              <v-btn v-on="on" fab x-small color="info" @click="(dialog = true) && downloadPdf()">
                                  <v-icon medium>mdi-printer</v-icon>
                              </v-btn>
                          </template>
                          <span>Imprimir Contrato</span>
                      </v-tooltip>
                  </v-col>
              </v-row>
          </v-card-title>

          <hr>
          <v-container style="padding: 25px;">
          <vue-html2pdf
              :show-layout="true"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="false"
              :filename="`Contrato_${capitalize(data.NtpFullName)}_${data.PrsDocumentNumber}`"
              :paginate-elements-by-height="1100"
              :pdf-quality="2"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="695px" 
              :manual-pagination="true"
              :htmlToPdfOptions = "htmlToPdfOptions"
              @progress="onProgress($event)"
              @startPagination="startPagination()"
              @hasPaginated="hasPaginated()"
              @beforeDownload="beforeDownload($event)"
              @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">	
              <section slot="pdf-content"> 
                  <v-container style="padding: 0%;" class="pdf-item">                    
                      <v-row>
                          <v-col style="font-family:Arial, Helvetica, sans-serif; font-weight:normal; font-size: 11px;">
                              <p style="margin: 0%; padding: 0%; text-align: center; font-weight: bold; text-decoration: underline; page-break-inside: avoid;">
                                CONTRATO DE TRABAJO SUJETO A MODALIDAD POR INCREMENTO DE ACTIVIDAD
                              </p>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col style="font-family:Arial, Helvetica, sans-serif; font-weight:normal; font-size: 10px; line-height: 11.6px;">
                            <p style="margin: 0%; padding: 0%; text-align: justify; page-break-inside: avoid;">
                              Conste por el presente documento el <b>“CONTRATO DE TRABAJO SUJETO A MODALIDAD POR INCREMENTO DE ACTIVIDAD”</b> que celebran al amparo del Art. 57º de la Ley de Productividad y Competitividad Laboral aprobado por D. S. Nº 003-97-TR y normas complementarias, de una parte <b>SUNSHINE EXPORT S.A.C., con R.U.C. N' 20329725431</b>, con domicilio en Carretera Tambo grande las Lomas km. 1077 + 179 Mts, distrito de Tambo grande, provincia y departamento de Piura, debidamente representada por el Sr. <b>HECTOR AQUILES GUILLEN VASQUEZ</b> en su calidad de <b>GERENTE DE RECURSOS HUMANOS</b> identificado con D.N.I. N° 09556316, a quien en adelante se denominará <b>EL EMPLEADOR</b>, y de la otra parte, el <b>Sr.(a) {{capitalize(data.NtpFullName, false)}}</b>, de nacionalidad {{data.DedFormatNationality ?  data.DedFormatNationality : 'peruana'}}, identificado(a) con <b>{{data.DedDescriptionDocument}} N° {{data.PrsDocumentNumber}}</b>, con domicilio en <b style="text-transform: uppercase;">{{data.PadAddress}}</b>, distrito de <b>{{ data.DisName }}</b>, provincia de <b>{{data.ProName}}</b> y departamento de <b>{{data.DepName}}</b>, a quien en adelante se denominará <b>EL TRABAJADOR</b> en los términos y condiciones siguientes:
                            </p>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA NATURALEZA DE LAS ACTIVIDADES DEL EMPLEADOR
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL EMPLEADOR</b> es una Empresa Agroindustrial creada como una persona jurídica de derecho privado, constituida bajo el régimen de la Sociedad Anónima Cerrada, y dedicada a la siembra, cultivo, cosecha, procesamiento, empaque y exportación de frutas frescas, congeladas.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL EMPLEADOR</b> se encuentra comprendido dentro de los alcances de la Ley N° 31110, vigente desde el 01 de enero del 2021; del D.S. N° 005-2021-MINAGRI REGLAMENTO DE LA LEY N°31110 y D.S. N° 006-2021-TR. Vigentes desde el 01 de abril de 2021.
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                
                                {{ array[aumnent()] }}: DE LA CAUSA OBJETIVA
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Por el presente documento <b>EL EMPLEADOR</b> contrata de manera <b>TEMPORAL</b> bajo la modalidad de contrato por Incremento de Actividad, los servicios de <b>EL TRABAJADOR</b> quien desempeñará el cargo de <b>{{data.PstName}}</b>, la cual se  requiere cubrir de manera temporal para las necesidades de recursos humanos originados por el incremento en el volumen de producción con la implementación de la nueva Planta de Frescos y el alquiler de nuevos fundos agrícolas, lo que generará un incremento de sus ventas, mayor materia prima para procesar, mayor actividad administrativa y operaria. 
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA NATURALEZA DE LAS LABORES DE EL TRABAJADOR
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> desempeñará sus labores en el cargo de <b>{{data.PstName}}</b>, y dependerá funcionalmente del <b>{{ data.AreNameJefatura }}</b>, cargos adscritos a la Gerencia <b>{{ data.AreNameGerencia }}</b>. Las labores que realizará el trabajador están directamente vinculadas a su cargo dentro del área de <b>{{ data.AreName }}</b> para la que se le ha contratado, dentro de dichas labores se comprenden las siguientes:
                              </p>
                            </div>
                            <br>
                            <ul style="margin-left: 10px;page-break-inside: avoid;">
                                <li v-for="(item, index) in data.itemsFunciones" :key="index">{{item.PfnDescription}}</li>
                            </ul>
                            <br>
                            <p v-if="data.TypeSpecialSituation == 5" style="margin: 0%; padding: 0%; text-align: justify;">
                                Por el presente se le comunica a EL TRABAJADOR que el cargo asignado es calificado por EL EMPLEADOR como CARGO DE CONFIANZA, de acuerdo a lo estipulado con el artículo 43 del Texto Único Ordenado del D. Leg. 728 Ley de Productividad y Competitividad Laboral aprobado por D.S. 003-97-TR con todas las implicancias de ley.
                              </p>
                            <p style="margin: 0%; padding: 0%; text-align: justify;page-break-inside: avoid;">
                              Ambas partes de común acuerdo declaran y aceptan que la presente enumeración de funciones es meramente enunciativa, mas no limitativa; siendo que, corresponden a EL TRABAJADOR todas las labores que resulten inherentes y complementarias al puesto de trabajo para el que se le contrata, así como, todas las que se establezcan en los documentos internos de gestión, como, por ejemplo, el Manual de Organización y Funciones, para su cargo. Asimismo, se indica que su Supervisor y/o Jefe Inmediato le puede indicar la realización de las labores que se encuentren en su Manual de Organización y Funciones, y otras vinculadas al cargo por el que se le contrata. Queda entendido que la prestación de servicios deberá ser efectuada de manera personal, no pudiendo EL TRABAJADOR ser reemplazado ni ayudado por tercera persona.
                            </p>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA DURACION DEL CONTRATO
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                El presente contrato rige desde el {{this.$fun.formatDateView(data.AgtBeginDate)}} hasta el {{this.$fun.formatDateView(data.AgtEndDate)}}. No obstante, en caso las labores que fundamentan la presente contratación concluyesen antes del plazo indicado en la presente cláusula, previo informe de la Gerencia responsable, las partes acuerdan que en dicho momento el contrato de trabajo finalizará, extinguiéndose así el vínculo laboral sin necesidad de que se cumpla el plazo convenido. Este supuesto de “Extinción del Contrato de Trabajo por Cumplimiento de Condición Resolutoria” se encuentra previsto en el inciso c) del artículo 16 del Texto Único Ordenado de la Ley de Productividad y Competitividad Laboral, aprobado mediante Decreto Supremo Nº 003-97-TR. 
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DEL PERIODO DE PRUEBA
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Que, respecto al periodo de prueba, las partes se remiten a lo establecido por el artículo 10 del Texto Único Ordenado del Decreto Legislativo N° 728, Ley de Productividad y Competitividad Laboral.
                              </p>
                              <p v-if="data.AgtMonthsProbation != null && data.AgtMonthsProbation != 3" style="margin: 0%; padding: 0%; text-align: justify;">
                                Teniendo en cuenta el puesto de trabajo y las labores a realizar este será de: <b>{{data.AgtMonthsProbation}} Meses</b>
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA REMUNERACIÓN
                              </p>
                              <p v-if="data.TypeLaborRegime == 1 && data.TypePayroll == 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                Conforme a lo dispuesto por el inciso e) del artículo 2° de la Ley N° 31110, EL TRABAJADOR se encuentra sujeto bajo los alcances del Régimen General de la actividad privada, por lo cual se le asigna un Remuneración Básica Mensual de <b v-if="viewsalary">S/. {{ parseFloat(descryptSalary(data.AgtSalary)).toFixed(2) }} soles ({{numberToWords(descryptSalary(data.AgtSalary))}});</b> <b v-if="!viewsalary"> (El salario solo se muestra cuando el contrato es impreso.) </b>, con un pago de frecuencia Mensual; y los beneficios por ley que correspondan.
                              </p>
                              <p v-if="data.TypeLaborRegime == 18 && data.TypePayroll == 4" style="margin: 0%; padding: 0%; text-align: justify;">
                                Conforme a lo dispuesto por la Ley N° 31110; del D.S. N° 005-2021-MINAGRI REGLAMENTO DE LA LEY N°31110 y D.S. N° 006-2021-TR. Vigentes desde el 01 de abril de 2021., EL TRABAJADOR percibirá una Remuneración Básica mensual ascendente a la suma de <b v-if="viewsalary">S/. {{ parseFloat(descryptSalary(data.AgtSalary)).toFixed(2) }} soles ({{numberToWords(descryptSalary(data.AgtSalary))}});</b> <b v-if="!viewsalary"> (El salario solo se muestra cuando el contrato es impreso.) </b>, con un pago de frecuencia mensual; adicional a ello se incluirá por conceptos de Gratificación y CTS el 16.66 % y 9.72%, respectivamente, sobre la Remuneración Básica, salvo elección contraria del trabajador, la cual se debe dejar constancia por escrito.
                              </p>
                              <p v-if="data.TypeLaborRegime == 1 && data.TypePayroll == 2" style="margin: 0%; padding: 0%; text-align: justify;">
                                Conforme a lo dispuesto por el inciso e) del artículo 2° de la Ley N° 31110, EL TRABAJADOR se encuentra sujeto bajo los alcances del Régimen General de la actividad privada, por lo cual se le asigna un Remuneración Básica Diaria de <b v-if="viewsalary">S/. {{ parseFloat(descryptSalary(data.AgtSalary)).toFixed(2) }} soles ({{numberToWords(descryptSalary(data.AgtSalary))}});</b> <b v-if="!viewsalary"> (El salario solo se muestra cuando el contrato es impreso.) </b>, con un pago de frecuencia Semanal; y los beneficios por ley que correspondan.
                              </p>
                              <p v-if="data.TypeLaborRegime == 18 && data.TypePayroll == 5" style="margin: 0%; padding: 0%; text-align: justify;">
                                Conforme a lo dispuesto por la Ley N° 31110; del D.S. N° 005-2021-MINAGRI REGLAMENTO DE LA LEY N°31110 y D.S. N° 006-2021-TR. Vigentes desde el 01 de abril de 2021., EL TRABAJADOR percibirá una Remuneración Básica diaria ascendente a la suma de <b v-if="viewsalary">S/. {{ parseFloat(descryptSalary(data.AgtSalary)).toFixed(2) }} soles ({{numberToWords(descryptSalary(data.AgtSalary))}});</b> <b v-if="!viewsalary"> (El salario solo se muestra cuando el contrato es impreso.) </b>, con un pago de frecuencia Semanal; adicional a ello se incluirá por conceptos de Gratificación y CTS el 16.66 % y 9.72%, respectivamente, sobre la Remuneración Básica, que en su conjunto formarán la Remuneración Diaria percibida por EL TRABAJADOR, salvo elección contraria del trabajador, la cual se debe dejar constancia por escrito.
                              </p>
                            </div>
                            <div v-if="(data.TypeLaborRegime == 18 && data.TypePayroll == 4) || (data.TypeLaborRegime == 18 && data.TypePayroll == 5)" style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA BONIFICACION ESPECIAL POR TRABAJO AGRARIO
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Conforme a lo dispuesto por la Ley N° 31110; del D.S. N° 005-2021-MINAGRI REGLAMENTO DE LA LEY N°31110 y D.S. N° 006-2021-TR. Vigentes desde el 01 de abril de 2021, <b>EL TRABAJADOR</b> percibirá adicionalmente a la Remuneración Básica una bonificación Especial por Trabajo Agrario (BETA) que asciende al 30% de la Remuneración Mínima Vital (RMV) vigente a la fecha de pago, el mismo que para todo efecto legal no constituye remuneración por ser de carácter no remunerativo. El BETA será de carácter mensual, el mismo que podrá pagarse diariamente en función al número de días efectivamente laborados.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Las partes acuerdan se realice el pago de la bonificación especial por trabajo agrario (beta) de forma prorrateada juntamente con el pago de la remuneración diaria, computándose, para tal efecto solo los días efectivamente laborados y todos aquellos que sean considerado como tal según norma expresa, en cumplimiento de lo establecido en el Art. 9 del D. S. N° 005-2021-MINAGRI -Reglamento de la Ley N° 31110, Ley del Régimen Laboral Agrario y de Incentivos para el Sector Agrario y Riego, Agroexportador y Agroindustria.
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA JORNADA Y LUGAR DE TRABAJO
                              </p>
                              <p v-if="data.TypeSpecialSituation != 6 && data.PstMedical != 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> deberá cumplir una jornada diaria no inferior de ocho (08) horas hasta completar las cuarenta y ocho (48) horas semanales, sujetándose a los horarios y turnos de trabajo que establezca <b>EL EMPLEADOR</b> durante su actividad, reservándose la facultad de poder señalar los horarios de labores que debe desempeñar <b>EL TRABAJADOR</b>, el horario de refrigerio y la designación del dia de descanso semanal que puede ser en domingo o en cualquier otro dia de la semana, y todas las demás facultades que el contrato de trabajo y las leyes laborales le reconocen.
                              </p>
                              <p v-if="data.TypeSpecialSituation != 6 && data.PstMedical != 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                Las partes acuerdan que la jornada laboral será comunicada directa y oportunamente por <b>EL EMPLEADOR</b>, asi como también el lugar de sede de labores el mismo que puede vanar dependiendo de las necesidades de <b>EL EMPLEADOR</b>. En uso de sus facultades directrices, <b>EL EMPLEADOR</b> está facultado a realizar modificaciones razonables en la jornada y lugar de trabajo de acuerdo a sus necesidades operativas, sin que dichas variaciones signifiquen menoscabo de categoria y/o remuneración.
                              </p>
                              <p v-else-if="data.PstMedical == 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> deberá cumplir una jornada diaria no inferior de Seis (06) horas hasta completar las Treinta y Seis (36) horas semanales, sujetándose a los horarios y turnos de trabajo que establezca <b>EL EMPLEADOR</b> durante su actividad, reservándose la facultad de poder señalar los horarios de labores que debe desempeñar <b>EL TRABAJADOR</b>, el horario de refrigerio y la designación del día de descanso semanal que puede ser en domingo o en cualquier otro día de la semana, y todas las demás facultades que el contrato de trabajo y las leyes laborales le reconocen.
                              </p>
                              <p v-else-if="data.PstMedical == 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                Las partes acuerdan que la jornada laboral será comunicada directa y oportunamente por <b>EL EMPLEADOR</b>, así como también el lugar de sede de labores, el mismo que puede variar dependiendo de las necesidades de <b>EL EMPLEADOR</b>. En uso de sus facultades directrices, <b>EL EMPLEADOR</b> está facultado a realizar modificaciones razonables en la jornada y lugar de trabajo de acuerdo a sus necesidades operativas, sin que dichas variaciones signifiquen menoscabo de categoría y/o remuneración.
                              </p>
                              <p v-if="data.TypeSpecialSituation == 6 && data.PstMedical != 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                EL TRABAJADOR ha sido calificado como trabajador no sujeto a fiscalización inmediata, por las labores asignadas a este cargo, según lo establecido en la cláusula tercera del presente contrato, éste se encuentra excluido del ámbito de aplicación del TUO del Decreto Legislativo N° 854, Ley de Jornada de Trabajo, Horario y Trabajo en Sobretiempo, aprobado por Decreto Supremo N° 007-2002-TR, por consiguiente, no se le aplicará la jornada máxima de trabajo, ni tampoco el régimen de horas extras.
                              </p>
                              <p v-if="data.TypeSpecialSituation == 6 && data.PstMedical != 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                Por la misma razón EL TRABAJADOR tampoco estará obligado a registrar sus ingresos y salidas al centro de trabajo, según lo establece el Decreto Supremo N° 004-2006-TR.
                              </p>
                              <p v-if="data.TypeSpecialSituation == 6 && data.PstMedical != 1" style="margin: 0%; padding: 0%; text-align: justify;">
                                EL TRABAJADOR observará bajo responsabilidad, el horario de trabajo establecido por LA EMPRESA, según lo dispuesto por el T.U.O. del Decreto Legislativo 854, Ley de la Jornada de Trabajo, Horario y Trabajo en Sobre tiempo, y su Reglamento el D.S. N°.008-2002-TR, no constituyendo, sin embargo, derecho para el trabajador el laborar en horarios de sobretiempo.
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LA CONFIDENCIALIDAD
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> se obliga a mantener en estricta confidencialidad, y consecuentemente no divulgar a terceros ajenos a la empresa. dentro de los que se incluyen a los medios de comunicación, toda información de campo, legal, financiera, contable, de seguridad o aquella relativa al desarrollo de sus funciones relacionadas a las operaciones o actividades de <b>EL EMPLEADOR</b>, incluidos sus clientes o el diseño de sus sistemas y procesos de cosecha exportación, importación, producción y/o comercialización y marketing, ya sea que estén incorporados o no en documentos escritos, archivos, cintas magnéticas, casetes, disqueles, videos, películas, entre otros medios que le fueran proporcionados directa o indirectamente por ejecutivos, analistas financieros, contadores o abogados u otros, relacionados directa o indirectamente con <b>EL EMPLEADOR</b>.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> se compromete a no retirar de la sede laboral vinculada a <b>EL EMPLEADOR</b>, mediante medios físicos, electrónicos u otros, procesos o programas relacionado a sus labores, ni ninguno de cualquier tipo. Además de las obligaciones anteriores que son de índole personal, <b>EL TRABAJADOR</b> velará para que durante el período que se realice sus labores, terceras personas no tengan acceso a retirar parcial o totalmente cualquiera de los procesos, procedimientos, o programas de cualquier tipo de propiedad de <b>EL EMPLEADOR</b> o información relativa a sus clientes. <b>EL TRABAJADOR</b>, responderá ante <b>EL EMPLEADOR</b> por los daños y perjuicios que cause derivados del incumplimiento de lo previsto en esta cláusula, sin perjuicio de la incursión en falta grave por expreso incumplimiento de sus obligaciones laborales y la buena fe laboral, conforme a lo previsto en la Ley de Productividad y Competitividad Laboral, lo cual configura causal de despido justificado. 
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: PROTECCIÓN DE DATOS PERSONALES
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL TRABAJADOR</b> en cumplimiento de lo dispuesto por la Ley N° 29733, Ley de Protección de Datos Personales, y su Reglamento, declara conocer que los datos personales consignados en el presente contrato, en la ficha personal, en video vigilancia y los demás documentos laborales quedarán incorporados en las bases de datos de EL EMPLEADOR; autorizando expresamente a que los mismos sean tratados en todos los temas relacionados al vínculo laboral existente y también por razones de seguridad.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                <b>EL EMPLEADOR</b> manifiesta cumplir con la normativa vigente en materia de protección de datos personales y, en particular, con las medidas de seguridad correspondientes, sobre todo porque algunos son calificados como datos sensibles; por tal motivo, se obliga a utilizar los datos brindados única y exclusivamente para los fines establecidos, guardando confidencialidad respecto a todos los datos de carácter personal que conozca y a los que tenga acceso.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Asimismo, <b>EL EMPLEADOR</b> se obliga a custodiar e impedir el acceso a los datos de carácter personal a cualquier tercero ajeno; garantizando que <b>EL TRABAJADOR</b> ejerza, de ser el caso, los derechos de acceso, rectificación, cancelación y oposición de sus datos personales, solicitándolo al Área de Recursos Humanos o al Área que haga sus veces. Por su parte, <b>EL TRABAJADOR</b> declara que, si por la naturaleza de su puesto tuviese acceso a las bases de datos de <b>EL EMPLEADOR</b> y/o de sus clientes, se compromete a cumplir con todas las medidas de seguridad implementadas por la empresa, respetando la normativa de Protección de Datos Personales.
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: DE LAS MEDIDAS DE SEGURIDAD Y SALUD EN EL TRABAJO
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Las partes declaran que en aplicación del literal c) del artículo 35º de la Ley N° 29783, Ley de Seguridad y Salud en el Trabajo y al artículo 30° del Reglamento de la ley antes citada, se le ha informado a <b>EL TRABAJADOR</b> la descripción de las recomendaciones de seguridad y salud en el trabajo relacionadas con el centro de trabajo en que laborará y especialmente del puesto de trabajo que ocupará, las mismas se encuentran detallas a continuación:
                              </p>
                              <ol type="a" style="margin-left: 15px;">
                                <li>
                                  Cumplir con las normas, reglamentos e instrucciones de los programas de seguridad y salud en el trabajo de <b>EL EMPLEADOR</b>;</li>
                                <li>
                                  Usar adecuadamente los instrumentos y materiales de trabajo, así como los equipos de protección personal y colectiva, para lo cual debe ser previamente informado y capacitado sobre su uso;
                                </li>
                                <li>
                                  No operar o manipular equipos, maquinarias, herramientas, u otros elementos para los cuales no haya sido autorizada;
                                </li>
                                <li>
                                  Comunicar a <b>EL EMPLEADOR</b> todo evento o situación que ponga o pueda poner en riesgo su seguridad y salud o las instalaciones físicas, debiendo adoptar inmediatamente, de ser posible, las medidas correctivas del caso sin que genere sanción de ningún tipo;
                                </li>
                                <li>
                                  Reportar a los representantes o delegados de seguridad, de forma inmediata, la ocurrencia de cualquier incidente, accidente de trabajo o enfermedad profesional;
                                </li>
                                <li>
                                  Responder e informar con veracidad a las instancias públicas que se lo requieran, caso contrario será considerado falta grave, sin perjuicio de la denuncia penal correspondiente.
                                </li>
                                <li>
                                  No intentar hacer reparaciones en los equipos, máquinas o demás materiales de trabajo para los que no esté capacitado; o, de estar capacitado para ello, no tomar las medidas de seguridad razonables para evitar incidentes o accidentes, durante la referida reparación.
                                </li>
                              </ol>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: SUSPENSIÓN DE LABORES
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Las partes convienen que en el supuesto de presentarse un caso fortuito y/o fuerza mayor que paralice las operaciones o los contratos que haya suscrito <b>EL EMPLEADOR</b> y que justifican la contratación de <b>EL TRABAJADOR</b>, el presente contrato laboral quedará suspendido de manera perfecta por el periodo de tiempo que dure tal situación, ello al amparo de lo establecido en el artículo 15º del D.S. Nº 003-97-TR; siendo que en caso se mantenga la situación que dio origen a la suspensión perfecta del contrato de trabajo por un periodo igual o superior a 90 días, las partes darán por resuelto el presente contrato sin derecho a indemnización alguna, ello al amparo de lo establecido en el artículo16º, literal h) del D.S. Nº 003-97-TR. En caso la empresa entre en un periodo de crisis económica por el descenso abrupto de sus ventas (o por cualquier otra razón) o en caso de presentarse la amenaza de quiebra, <b>EL TRABAJADOR</b> se compromete a apoyar a la empresa en todas las nuevas labores y esfuerzos que resulten necesarios para superar la mencionadas situaciones, e incluso buscará llegar a un acuerdo respecto a la variación de su remuneración establecida en la cláusula sexta; todo lo cual, se deberá llevar a cabo dentro de criterios de razonabilidad y por el tiempo que dure la crisis económica de la empresa.
                              </p>
                            </div>
                            <div style="page-break-inside: avoid;">
                              <p v-once style="margin: 0%; padding: 0%; text-align: justify; text-decoration: underline; font-weight: bold;">
                                {{ array[aumnent()] }}: RESOLUCIÓN DEL CONTRATO
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                El presente contrato podrá concluir por cualquiera de las causas previstas en el artículo 16° en la Ley de Productividad y Competitividad Laboral, aprobado por el D.S. 003-97-TR. En este sentido, <b>EL EMPLEADOR</b> podrá despedir a <b>EL TRABAJADOR</b> de manera justificada por causas vinculadas con la capacidad o la conducta del mismo, siguiendo el procedimiento establecido legalmente. Asimismo, conforme a la cláusula CUARTA, el presente contrato culminará al terminar la campaña de mango, siendo que, en caso la campaña culmine antes, debido a diversos factores (como factores climatológicos, productivos, entre otros), el presente contrato se resolverá automáticamente conforme a lo dispuesto en el literal c) del artículo 16° del D.S. 003-97-TR. Por otra parte, en caso se dé un caso fortuito o de fuerza mayor, como una pandemia y/o un Estado de Emergencia, ambas partes establecen que el presente contrato se extinguirá automáticamente en caso se dicten normas que establezcan medidas restrictivas destinadas a mitigar la contingencia y que se extienda por un plazo mayor a los 90 días, como puede ser evitar la propagación de una enfermedad que ponga en riesgo la salud de <b>EL TRABAJADOR</b> y/o de La Nación Peruana en general, y que hagan imposible la prestación de servicios presenciales de <b>EL TRABAJADOR</b> en el centro de trabajo que se le ha designado, siempre que, en atención a la naturaleza de las labores, <b>EL TRABAJADOR</b> no pueda realizar TODAS sus funciones y bajo la modalidad de trabajo remoto o teletrabajo o cualquier otra modalidad que se establezca mediante la que se pueda prestar servicios a través de Tecnologías de la Información y Comunicación. 
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                En este apartado, <b>EL TRABAJADOR</b> declara conocer y aceptar las condiciones resolutorias pactadas y los efectos legales que su inclusión conlleva respecto a la extinción de su relación laboral.
                              </p>
                              <p style="margin: 0%; padding: 0%; text-align: justify;">
                                Hecho en tres ejemplares de un mismo tenor y para un solo efecto, que se firma por las partes en señal de conformidad, en la ciudad de Tambo grande, al {{this.$fun.formatDateView(data.AgtBeginDate)}}.
                              </p>
                            </div>
                          </v-col>
                      </v-row>
                      <br>
                      <v-row style="page-break-inside: avoid;">
                          <v-col style="font-family:Arial, Helvetica, sans-serif; font-weight:normal; font-size: 12px; display: flex; justify-content: space-evenly; align-items: end;">
                            <p style="font-weight: bold; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative;">
                                <img v-if="data.TypePayroll == 2 || data.TypePayroll == 5" src="/static/firmaContrato.jpeg" width="140" alt="" style="padding-bottom: 10px;">
                                <span v-else style="height:71px;"></span>
                                <span style="text-decoration: overline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SUNSHINE EXPORT SAC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>HECTOR AQUILES GUILLEN VASQUEZ</span>
                                <span>D.N.I. 09556316</span>
                              </p>
                              <p style="font-weight: bold; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative;">
                                <span style="text-decoration: overline;">&nbsp;&nbsp;{{capitalize(data.NtpFullName, false)}}&nbsp;&nbsp;</span>
                                <span>{{data.DedDescriptionDocument}} N° {{data.PrsDocumentNumber}}</span>
                                <span>&nbsp;</span>
                              </p>
                          </v-col>
                      </v-row>
                  </v-container>
              </section>
          </vue-html2pdf>
        </v-container>
      </v-card>
      <v-dialog v-model="dialog" max-width="320" persistent color="success">
          <v-container style="display: flex; justify-content: center; flex-direction: column; margin-top: 30px;">
              <v-row>
                  <v-col cols="12">
                      <div style="display: flex; justify-content: center;">
                          <div class="typewriter">
                              <div class="slide"><i></i></div>
                              <div class="paper"></div>
                              <div class="keyboard"></div>
                          </div>
                      </div>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col>
                      <p style="color: #fff; font-weight: 500; text-align: center;">
                          Generando contrato ...
                      </p>
                  </v-col>
              </v-row>
          </v-container>
      </v-dialog>
  </div>
</template>
<script>
  import VueHtml2pdf from 'vue-html2pdf'
  import qrCode from "vue-qr-generator";
  import CryptoJS from 'crypto-js';
  import _constapp from "@/plugins/const";
  import _sContractService 	from '@/services/HumanResource/HmnContractService.js';

  export default{ 
     
      props:{
          data: {
            type: Object,
            default: null,
          },
          preventIndividualDownload: {
            type: Boolean,
            default: false
          }
      },
      components:{
          VueHtml2pdf,
          qrCode
      },
      data(){
          return{
              dialog: false,
              viewsalary: false,
              htmlToPdfOptions: {
                margin: [0.2, 0.5, 0.2, 0.5],
                filename: `Contrato_${this.capitalize(this.data.NtpFullName)}_${this.data.PrsDocumentNumber}.pdf`,
                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                },
                html2canvas: {
                  useCORS: true,
                  scale: 6,
                },
              },
              array: [
                'PRIMERO',
                'SEGUNDO',
                'TERCERO',
                'CUARTO',
                'QUINTO',
                'SEXTO',
                'SEPTIMO',
                'OCTAVO',
                'NOVENO',
                'DECIMO',
                'DECIMO PRIMERO',
                'DECIMO SEGUNDO',
                'DECIMO TERCERO',
                'DECIMO CUARTO',
                'DECIMO QUINTO',
              ],
              arrayinicio: -1,
          }
      },
  //     mounted() {
  //   this.incrementarIndice(); // Se ejecuta una sola vez cuando el componente carga
  // },
      methods:{
        getPDFBlob() {
          return new Promise((resolve, reject) => {
              try {
              // Guardar una referencia al componente html2pdf
              const html2PdfComponent = this.$refs.html2Pdf;
              
              // Guardar los handlers originales
              const originalBeforeDownload = this.beforeDownload;
              const originalHasDownloaded = this.hasDownloaded;
              
              // Variable para controlar si la promesa ya se resolvió
              let isResolved = false;
              
              // Crear un nuevo handler para beforeDownload
              this.beforeDownload = ({ html2pdf, options, pdfContent }) => {
                  // Capturar el PDF directamente desde la instancia html2pdf
                  html2pdf().set(options).from(pdfContent).outputPdf('blob').then(blob => {
                  if (!isResolved) {
                      isResolved = true;
                      resolve(blob);
                  }
                  
                  // Restaurar handlers originales
                  this.beforeDownload = originalBeforeDownload;
                  this.hasDownloaded = originalHasDownloaded;
                  
                  // Llamar al handler original si existe
                  if (typeof originalBeforeDownload === 'function') {
                      return originalBeforeDownload({ html2pdf, options, pdfContent });
                  }
                  return true; // Continuar con el proceso normal
                  }).catch(error => {
                  if (!isResolved) {
                      isResolved = true;
                      reject(error);
                  }
                  
                  // Restaurar handlers originales
                  this.beforeDownload = originalBeforeDownload;
                  this.hasDownloaded = originalHasDownloaded;
                  });
                  
                  // Prevenir la descarga automática retornando false
                  return false;
              };
              
              // Iniciar la generación del PDF
              html2PdfComponent.generatePdf();
              
              // Establecer un timeout más largo
              setTimeout(() => {
                  if (!isResolved) {
                  isResolved = true;
                  
                  // Restaurar handlers originales
                  this.beforeDownload = originalBeforeDownload;
                  this.hasDownloaded = originalHasDownloaded;
                  
                  reject(new Error('No se pudo obtener el blob del PDF: timeout después de 10 segundos'));
                  }
              }, 10000); // 10 segundos
              
              } catch (error) {
              console.error('Error en getPDFBlob:', error);
              reject(error);
              }
          });
        },
        aumnent(){
          this.arrayinicio  = this.arrayinicio + 1;
          return this.arrayinicio ;
        },
          incrementarIndice() {
            if (this.inicioArray < this.array.length - 1) {
              this.inicioArray++;
            }
            return true;
          },
          capitalize(str, capitalize = true) {
              if(capitalize){
                return str.trim().split(/\s+/).map(palabra => 
                    palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
                ).join(" ");
              }else{
                return str.trim().split(/\s+/).map(palabra => 
                    palabra.toUpperCase()).join(" ");
              }
          },
          descryptSalary(salary) {
            //if(this.$fun.isAdmin() ) {
              // const secretKey = _constapp._cKey; // Clave secreta para desencriptar
              // const bytes = CryptoJS.AES.decrypt(salary, secretKey);
              // return bytes.toString(CryptoJS.enc.Utf8);}
              return this.$fun.decryptSalary(salary);
            //}
            //return "*****"; // Mostrar asteriscos si no tiene permiso
          },
          numberToWords(num) {
              const units = ["", "UNO", "DOS", "TRES", "CUATRO", "CINCO", "SEIS", "SIETE", "OCHO", "NUEVE"];
              const tens = ["", "DIEZ", "VEINTE", "TREINTA", "CUARENTA", "CINCUENTA", "SESENTA", "SETENTA", "OCHENTA", "NOVENTA"];
              const specials = ["DIEZ", "ONCE", "DOCE", "TRECE", "CATORCE", "QUINCE", "DIECISÉIS", "DIECISIETE", "DIECIOCHO", "DIECINUEVE"];
              const hundreds = ["", "CIEN", "DOSCIENTOS", "TRESCIENTOS", "CUATROCIENTOS", "QUINIENTOS", "SEISCIENTOS", "SETECIENTOS", "OCHOCIENTOS", "NOVECIENTOS"];

              function convertHundreds(n) {
                  if (n < 10) return units[n];
                  if (n < 20) return specials[n - 10];
                  if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " Y " + units[n % 10] : "");
                  return hundreds[Math.floor(n / 100)] + (n % 100 ? " " + convertHundreds(n % 100) : "");
              }

              function convertNumber(n) {
                  if (n === 0) return "CERO";
                  if (n < 1000) return convertHundreds(n);
                  if (n < 1000000) {
                      let thousands = Math.floor(n / 1000);
                      let remainder = n % 1000;
                      return (thousands === 1 ? "MIL" : convertHundreds(thousands) + " MIL") + (remainder ? " " + convertHundreds(remainder) : "");
                  }
                  return "NÚMERO DEMASIADO GRANDE";
              }

              const parts = num.split(".");
              const integerPart = parseInt(parts[0], 10);
              const decimalPart = parts.length > 1 ? parts[1] : "00";

              return `${convertNumber(integerPart)} CON ${decimalPart}/100 SOLES`;
          },
          downloadPdf(){
            console.log("Llama a funciona antes");
            this.viewsalary = true;
            if(this.data.AmsID){
              const items = {
                AmsID : this.data.AmsID,
                UsrCreateID : this.$fun.getUserID()
              }
              _sContractService.savePrint(items, this.$fun.getUserID())
              .then((r) => {
                console.log("Dataaaaaaaaaaaaa:", r.data)
              })
            }
            setTimeout(() => {
              this.$refs.html2Pdf.generatePdf();
            }, 300);
          },
          onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`)
    },
    startPagination() {
      console.log(`PDF has started pagination`)
    },

    hasPaginated () {
      console.log(`PDF has been paginated`)
    },

    beforeDownload ({ html2pdf, options, pdfContent }) {
      if(!this.preventIndividualDownload) {
        console.log(`On Before PDF Generation`)
        html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(150)
                // pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            } 
        }).save()
        .then(() => {
            this.viewsalary = false;
            this.dialog = false;
        });
      }
    },
      },
      mounted(){
          // console.log('print', this.data);
      }
  }
</script>

<style>
.v-dialog {
  box-shadow: none !important;
}
/* From Uiverse.io by Nawsome */ 
.typewriter {
--blue: #5C86FF;
--blue-dark: #275EFE;
--key: #fff;
--paper: #EEF0FD;
--text: #D3D4EC;
--tool: #FBC56C;
--duration: 3s;
position: relative;
-webkit-animation: bounce05 var(--duration) linear infinite;
animation: bounce05 var(--duration) linear infinite;
}

.typewriter .slide {
width: 92px;
height: 20px;
border-radius: 3px;
margin-left: 14px;
transform: translateX(14px);
background: linear-gradient(var(--blue), var(--blue-dark));
-webkit-animation: slide05 var(--duration) ease infinite;
animation: slide05 var(--duration) ease infinite;
}

.typewriter .slide:before, .typewriter .slide:after,
.typewriter .slide i:before {
content: "";
position: absolute;
background: var(--tool);
}

.typewriter .slide:before {
width: 2px;
height: 8px;
top: 6px;
left: 100%;
}

.typewriter .slide:after {
left: 94px;
top: 3px;
height: 14px;
width: 6px;
border-radius: 3px;
}

.typewriter .slide i {
display: block;
position: absolute;
right: 100%;
width: 6px;
height: 4px;
top: 4px;
background: var(--tool);
}

.typewriter .slide i:before {
right: 100%;
top: -2px;
width: 4px;
border-radius: 2px;
height: 14px;
}

.typewriter .paper {
position: absolute;
left: 24px;
top: -26px;
width: 40px;
height: 46px;
border-radius: 5px;
background: var(--paper);
transform: translateY(46px);
-webkit-animation: paper05 var(--duration) linear infinite;
animation: paper05 var(--duration) linear infinite;
}

.typewriter .paper:before {
content: "";
position: absolute;
left: 6px;
right: 6px;
top: 7px;
border-radius: 2px;
height: 4px;
transform: scaleY(0.8);
background: var(--text);
box-shadow: 0 12px 0 var(--text), 0 24px 0 var(--text), 0 36px 0 var(--text);
}

.typewriter .keyboard {
width: 120px;
height: 56px;
margin-top: -10px;
z-index: 1;
position: relative;
}

.typewriter .keyboard:before, .typewriter .keyboard:after {
content: "";
position: absolute;
}

.typewriter .keyboard:before {
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 7px;
background: linear-gradient(135deg, var(--blue), var(--blue-dark));
transform: perspective(10px) rotateX(2deg);
transform-origin: 50% 100%;
}

.typewriter .keyboard:after {
left: 2px;
top: 25px;
width: 11px;
height: 4px;
border-radius: 2px;
box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
-webkit-animation: keyboard05 var(--duration) linear infinite;
animation: keyboard05 var(--duration) linear infinite;
}

@keyframes bounce05 {
85%, 92%, 100% {
  transform: translateY(0);
}

89% {
  transform: translateY(-4px);
}

95% {
  transform: translateY(2px);
}
}

@keyframes slide05 {
5% {
  transform: translateX(14px);
}

15%, 30% {
  transform: translateX(6px);
}

40%, 55% {
  transform: translateX(0);
}

65%, 70% {
  transform: translateX(-4px);
}

80%, 89% {
  transform: translateX(-12px);
}

100% {
  transform: translateX(14px);
}
}

@keyframes paper05 {
5% {
  transform: translateY(46px);
}

20%, 30% {
  transform: translateY(34px);
}

40%, 55% {
  transform: translateY(22px);
}

65%, 70% {
  transform: translateY(10px);
}

80%, 85% {
  transform: translateY(0);
}

92%, 100% {
  transform: translateY(46px);
}
}

@keyframes keyboard05 {
5%, 12%, 21%, 30%, 39%, 48%, 57%, 66%, 75%, 84% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

9% {
  box-shadow: 15px 2px 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

18% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 2px 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

27% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 12px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

36% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 12px 0 var(--key), 60px 12px 0 var(--key), 68px 12px 0 var(--key), 83px 10px 0 var(--key);
}

45% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 2px 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

54% {
  box-shadow: 15px 0 0 var(--key), 30px 2px 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

63% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 12px 0 var(--key);
}

72% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 2px 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

81% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 12px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}
}
</style>