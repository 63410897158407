<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Designación de Puesto Critico"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'DESIGNACION_DE_PUESTO_CRITICO_' + this.itemSelect.NtpFullName + '_' + new Date().toLocaleDateString() + '.pdf'"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                :htmlToPdfOptions = "htmlToPdfOptions"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="font-family: Arial, sans-serif; margin: 0; padding: 20px;">
                        <div style="border: 1px solid #000; width: 730px; margin: 50px auto; padding: 0px;">
                            <!-- Encabezado con 3 secciones -->
                            <div style="display: flex; width: 100%; font-family: Arial, sans-serif; border-bottom: 1px solid #000">
                                <!-- Sección Izquierda - Logo -->
                                <div style="flex: 1; display: flex; justify-content: center; align-items: center; border-right: 1px solid black; padding: 10px;">
                                    <img src="/static/logo_sp.png" alt="Logo" style="width: 140px; height: auto;">
                                </div>

                                <!-- Sección Central - Título -->
                                <div style="flex: 2; display: flex; justify-content: center; align-items: center; border-right: 1px solid black; padding: 10px; text-align: center;">
                                    <h2 style="margin: 0; font-size: 14px; font-weight: bold;">DESIGNACION DE PUESTO CRITICO EN LA CADENA DE SUMINISTROS</h2>
                                </div>

                                <!-- Sección Derecha - Información -->
                                <div style="flex: 1; font-size: 9px; padding: 10px; text-align: center;">
                                    <div style="margin-bottom: 2px;"><strong>CÓDIGO: RH-FR-039</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>VERSIÓN: 03</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Fecha de Creación: 02/12/2021</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Última Revisión: {{ this.definitiveReview }}</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Páginas: 1 de 1</strong></div>
                                </div>
                            </div>
                            
                            <div style="display: flex; width: 100%; font-family: Arial, sans-serif;">
                                <!-- Sección Izquierda - Logo -->
                                <div style="flex: 3; display: flex; justify-content: center; align-items: center; border-right: 1px solid black; padding: 10px; height: 30px;">
                                    <div style="font-size: 12px;">
                                        <strong>Referencia:</strong> Normas Internacionales de Seguridad en la Cadena de Suministros.
                                    </div>
                                </div>

                                <!-- Sección Central - Título -->
                                <div style="flex: 1; display: flex; justify-content: right; align-items: center; border-right: 1px solid black; padding: 10px; text-align: center; height: 30px;">
                                    <div style="font-weight: bold; font-size: 12px; margin-right: 10px;">Fecha:</div>
                                </div>

                                <!-- Sección Derecha - Información -->
                                <div style="flex: 1; display: flex; justify-content: center; align-items: center; padding: 10px; text-align: center; height: 30px;">
                                    <div style="font-weight: bold; font-size: 12px; margin-right: 10px;">{{ $fun.formatDateView(this.itemSelect.AmsStartDateMovement) }}</div>
                                </div>
                            </div>

                            <!-- Sección I - DATOS GENERALES DEL TRABAJADOR -->
                            <div style="margin-bottom: 20px;">
                                <div style="padding: 5px 40px; text-align: left; font-weight: bold; margin-bottom: 15px; border-top: 1px solid #000; border-bottom: 1px solid #000">
                                    I. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DATOS GENERALES DEL TRABAJADOR
                                </div>
                                <div style="padding: 0px 20px;">
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 100px; font-weight: bold; font-size: 12px;">NOMBRE:</div>
                                        <div style="flex: 1; height: 20px; border-bottom: 1px dashed #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.NtpFullName }}</div>
                                        <div style="width: 70px; font-weight: bold; font-size: 12px; margin-left: 10px;">DNI N°:</div>
                                        <div style="width: 100px; height: 20px; border-bottom: 1px dashed #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PrsDocumentNumber }}</div>
                                    </div>
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 100px; font-weight: bold; font-size: 12px;">DIRECCION:</div>
                                        <div style="flex: 1; height: 20px; border-bottom: 1px dashed #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PadAddress }}</div>
                                        <div style="width: 70px; font-weight: bold; font-size: 12px; margin-left: 10px;">Teléfono:</div>
                                        <div style="width: 100px; height: 20px; border-bottom: 1px dashed #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.NumberPhone }}</div>
                                    </div>
                                </div>
                            </div>

                            <!-- Sección II - DESCRIPCION DEL PUESTO -->
                            <div style="margin-bottom: 20px;">
                                <div style="padding: 5px 40px; text-align: left; font-weight: bold; margin-bottom: 15px; border-top: 1px solid #000; border-bottom: 1px solid #000">
                                    II. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DESCRIPCION DEL PUESTO
                                </div>
                                <div style="padding: 0px 170px 0px 85px;">
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 170px; font-weight: bold; font-size: 12px;">NOMBRE DEL PUESTO:</div>
                                        <div style="flex: 1; height: 30px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PstName }}</div>
                                    </div>
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 170px; font-weight: bold; font-size: 12px;">AREA DEL PUESTO:</div>
                                        <div style="flex: 1; height: 30px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.AreName }}</div>
                                    </div>
                                </div>
                            </div>

                            <!-- Sección III - IDENTIFICACION DE PUESTO CRITICO -->
                            <div style="margin-bottom: 20px;">
                                <div style="padding: 5px 40px; text-align: left; font-weight: bold; margin-bottom: 15px; border-top: 1px solid #000; border-bottom: 1px solid #000">
                                    III. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IDENTIFICACION DE PUESTO CRITICO
                                </div>
                                <div style="padding: 0px 20px; font-size: 12px; text-align: justify; line-height: 1.4;">
                                    Por medio del presente, SUNSHINE EXPORT S.A.C. le hace de conocimiento a EL TRABAJADOR ocupante del cargo que en el desempeño de sus funciones realiza trabajos de acceso a zonas sensibles y críticas según los protocolos de seguridad en la cadena de suministros, en cumplimiento con los procedimientos de acceso y asumiendo la responsabilidad de toda actividad ilícita. En ese sentido, manifiesta su pleno consentimiento para cuando le sea requerido por La Empresa se someta a todos los exámenes médicos, toxicológicos o de cualquier otra índole que considere pertinente.
                                    Asimismo, otorgará su conformidad y brindará todas las facilidades para que la Empresa pueda realizar la correspondiente visita domiciliaria por parte de la Trabajadora Social, en el domicilio consignado en la presente comunicación; en caso de variación de domicilio, el trabajador deberá proporcionar oportunamente y por escrito su nueva dirección ante la oficina de Recursos Humanos. <br>
                                    En caso de negativa de lo aquí estipulado constituye falta grave que se puede sancionar con Despido. <br>
                                    Cualquier información adicional que Ud. requiera conocer será proporcionada por la Jefatura de Administración de Personal y Compensaciones. <br>
                                    Normas y/o Protocolos de Referencia:
                                    <ol style="margin-left: 20px; padding-left: 0;">
                                        <li> &nbsp;&nbsp;&nbsp; Norma BASC V06:2022 y el Estándar 6.01</li>
                                        <li> &nbsp;&nbsp;&nbsp; Checklist Supply Chain Security - Walmart Mayo -2024</li>
                                    </ol>
                                </div>
                            </div>

                            <div style="border-top: 1px solid #000; margin: 20px 0;"></div>

                            <!-- Pie de página con firmas -->
                            <div style="display: flex; justify-content: space-around; padding: 20px; margin-top: 30px;">
                                <!-- Firma del trabajador y huella -->
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div style="display: flex; border: 1px solid #000; width: 250px; height: 80px;">
                                        <!-- Espacio para la firma -->
                                        <div style="flex: 3; border-right: 1px solid #000;"></div>
                                        
                                        <!-- Espacio para la huella -->
                                        <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
                                            <img :src="fingerprintImage" v-if="fingerprintImage" 
                                                alt="Huella Dactilar" 
                                                style="max-width: 100%; max-height: 100%; object-fit: contain;" />
                                        </div>
                                    </div>

                                    <div style="display: flex; border: 1px solid #000; border-top: none; width: 250px; font-size: 12px; font-weight: bold;">
                                        <div style="flex: 3; text-align: center; border-right: 1px solid #000;">FIRMA DEL TRABAJADOR</div>
                                        <div style="flex: 1; text-align: center;">HUELLA</div>
                                    </div>
                                </div>

                                <!-- Firma del responsable de RR.HH. -->
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div style="border: 1px solid #000; width: 250px; height: 80px;"></div>
                                    <div style="border: 1px solid #000; border-top: none; width: 250px; text-align: center; font-size: 12px; font-weight: bold; ">
                                        FIRMA DE RESPONSABLE DE RR.HH.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </vue-html2pdf>
                <v-card-actions>
                    <s-finger-print @fingerprintSaved="handleFingerprint"></s-finger-print>
                </v-card-actions>
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sHelper from "@/services/HelperService";
    import _sGenParam from "../../../services/General/ParameterService";
    import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";

    export default{ 
       
        props:{
            item: {
				type: Object,
				default: null,
			},
            itemSelect: {
				type: Object,
                default: null,
			},
            preventIndividualDownload: {
                type: Boolean,
                default: false
            }
        },
        components:{
            VueHtml2pdf,
            sFingerPrint
        },
        data(){
            return{
                urlData: "",
                image: null,
                contractData: [],
                MovemtsRegister: [],
                pensionCE: [],
                HealthCE: [],
                BankAccountsCE: [],
                totalpages: 0,
                Year: null,
                Month: null,
                Day: null,
                age: null,
                definitiveReview: null,
                fingerprintImage: "",
                htmlToPdfOptions: {
                    filename: `Designacion_de_puesto_critico.pdf`,
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait'
                    },
                    html2canvas: {
                    useCORS: true,
                    scale: 10,
                    },
                },
            }
        },
        methods:{
            getPDFBlob() {
                return new Promise((resolve, reject) => {
                    try {
                    // Guardar una referencia al componente html2pdf
                    const html2PdfComponent = this.$refs.html2Pdf;
                    
                    // Guardar los handlers originales
                    const originalBeforeDownload = this.beforeDownload;
                    const originalHasDownloaded = this.hasDownloaded;
                    
                    // Variable para controlar si la promesa ya se resolvió
                    let isResolved = false;
                    
                    // Crear un nuevo handler para beforeDownload
                    this.beforeDownload = ({ html2pdf, options, pdfContent }) => {
                        // Capturar el PDF directamente desde la instancia html2pdf
                        html2pdf().set(options).from(pdfContent).outputPdf('blob').then(blob => {
                            if (!isResolved) {
                                isResolved = true;
                                resolve(blob);
                            }
                            
                            // Restaurar handlers originales
                            this.beforeDownload = originalBeforeDownload;
                            this.hasDownloaded = originalHasDownloaded;
                            
                            // Llamar al handler original si existe
                            if (typeof originalBeforeDownload === 'function') {
                                return originalBeforeDownload({ html2pdf, options, pdfContent });
                            }
                            return true; // Continuar con el proceso normal
                        }).catch(error => {
                            if (!isResolved) {
                                isResolved = true;
                                reject(error);
                            }
                            
                            // Restaurar handlers originales
                            this.beforeDownload = originalBeforeDownload;
                            this.hasDownloaded = originalHasDownloaded;
                        });
                        
                        // Prevenir la descarga automática retornando false
                        return false;
                    };
                    
                    // Iniciar la generación del PDF
                    html2PdfComponent.generatePdf();
                    
                    // Establecer un timeout más largo
                    setTimeout(() => {
                        if (!isResolved) {
                        isResolved = true;
                        
                        // Restaurar handlers originales
                        this.beforeDownload = originalBeforeDownload;
                        this.hasDownloaded = originalHasDownloaded;
                        
                        reject(new Error('No se pudo obtener el blob del PDF: timeout después de 10 segundos'));
                        }
                    }, 10000); // 10 segundos
                    
                    } catch (error) {
                    console.error('Error en getPDFBlob:', error);
                    reject(error);
                    }
                });
            },
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				//console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				//console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				//console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                //console.log(`On Before PDF Generation`)
                if(!this.preventIndividualDownload) {
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
                }
            },
            formatDate(dateString) {
                const [day, month, year] = dateString.split('/');
                const months = {
                '01': 'enero',
                '02': 'febrero',
                '03': 'marzo',
                '04': 'abril',
                '05': 'mayo',
                '06': 'junio',
                '07': 'julio',
                '08': 'agosto',
                '09': 'septiembre',
                '10': 'octubre',
                '11': 'noviembre',
                '12': 'diciembre'
                };
                return `${parseInt(day)} de ${months[month]} del ${year}`;
            },
            getImagePerson(document) {
                _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
                if (rsp.status === 200) {
                    this.image = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente
                    return this.image;
                } else {
                    console.error("No se pudo cargar la imagen");
                }
                }).catch((error) => {
                console.error("Error al obtener la imagen:", error);
                });

            },
            extractDateParts(item) {
                if (item) {
                    const dateParts = item.split("T")[0].split("-");
                    this.Year = parseInt(dateParts[0]); // "2000"
                    this.Month = parseInt(dateParts[1]); // "01"
                    this.Day = parseInt(dateParts[2]); // "05"
                }
            },
            loadParameters() {
                _sGenParam
                    .search({ PrmName: "RH-FR-039" }, this.$fun.getUserID())
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.definitiveReview = resp.data.PrmValue;
                        }
                    });
            },
            handleFingerprint(image) {
                this.fingerprintImage = image; // Guarda la huella en una variable
            }
        },
        mounted(){
            this.extractDateParts(this.itemSelect.AgtBeginDate);
            this.loadParameters();
        }
    }
</script>